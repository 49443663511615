<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div
									class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{ mainData.kod }}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0"
										v-if="$router.options.history.state.back != null" icon="pi pi-times"
										@click="$router.go(-1)" />
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>
		<Message severity="success" icon="pi-lock-open" v-if="mainData.sonuc == 930710008">Aktivite durumu: <b>{{ mainData.sonucName }}</b></Message>
		<Message severity="warn" icon="pi-exclamation-triangle" v-if="mainData.sonuc == 930710001 || mainData.sonuc == 930710010">Aktivite durumu: <b>{{ mainData.sonucName }}</b></Message>
		<Message severity="error" icon="pi-lock" v-if="mainData.sonuc == 930710000">Aktivite durumu: <b>{{ mainData.sonucName }}</b></Message>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="SERVİS RAPORU" entityName="serviceappointment">
					</EntityHeader>
					<div class="grid">
						<div class="col-5">
							<div class="field p-fluid">
								<EntityLookup id="firma" v-model="mainData.firmaName" ref="entity_firma" label="Firma"
									entityName="account" nameField="name" :state="true" :required="true" :disabled="true"
									@itemSelected="firmaSelected = $event" @itemCleared="firmaSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.firmaName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.firmaName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="servisTalebi" v-model="mainData.servisTalebiName" ref="entity_servisTalebi" label="Servis Talebi"
									entityName="incident" nameField="title" secondNameField="ticketnumber" :state="true" :required="true" :disabled="isDisabled"
									parentFieldName="customerid" :parentId="firmaSelected"
									@itemSelected="servisTalebiSelected = $event" @itemCleared="servisTalebiSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.servisTalebiName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.servisTalebiName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<EntityLookup id="tezgah" v-model="mainData.tezgahName" ref="entity_tezgah"
									label="Tezgah" entityName="bm_unite" nameField="bm_serino" secondNameField="bm_urunid" secondNameFieldType="lookup" :state="true" :required="true"
									parentFieldName="bm_kurulummusteriid" :parentId="firmaSelected"
									@itemSelected="tezgahSelected = $event" @itemCleared="tezgahSelected = null" disabled>
								</EntityLookup>
								<span v-if="v$.mainData.tezgahName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.tezgahName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<EntityLookup id="urun" v-model="mainData.urunName" ref="entity_urun"
									label="Ürün" entityName="product" nameField="bm_name" :state="true" :required="true"
									@itemSelected="urunSelected = $event" @itemCleared="urunSelected = null" disabled
									secondNameField="productnumber">
								</EntityLookup>
								<span v-if="v$.mainData.urunName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.urunName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>İŞ EMRİ BÖLÜMÜ</h5>
					<div class="grid">
						<div class="col-9">
							<div class="field p-fluid">
								<label for="garantiDahilmi">Kaynaklar</label>
								<MultiSelect v-model="selectedResources" :options="resourcesData" optionLabel="name" placeholder="Kaynak seçiniz" :filter="true" class="multiselect-custom"  :disabled="isDisabled">
									<template #value="slotProps">
										<div class="country-item country-item-value" v-for="option of slotProps.value" :key="option.code">
											<img src="layout/images/entityImages/resource.gif" class="mr-2" width="18" />
											<div>{{option.name}}</div>
										</div>
										<template v-if="!slotProps.value || slotProps.value.length === 0">
											Kaynak seçiniz
										</template>
									</template>
									<template #option="slotProps">
										<div class="country-item">
											<img src="layout/images/entityImages/resource.gif" class="mr-2" width="18" />
											<div>{{slotProps.option.name}}</div>
											<div class="muted-text ml-7">{{slotProps.option.konum}}</div>
										</div>
									</template>
								</MultiSelect>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="turu">Türü <span style="color:red">*</span></label>
								<Dropdown id="turu" v-model="mainData.turu" :options="SM_turu" optionLabel="Value" optionValue="AttributeValue" placeholder="Türü" :showClear="true" :disabled="isDisabled"/>
								<span v-if="v$.mainData.turu.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.turu.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>SERVİS FORMU</h5>
					<div class="grid">
						<div class="col-4">
							<div class="field p-fluid">
								<label for="makinaCalismaSaati">Makina Çalışma Saati</label>
								<InputNumber id="makinaCalismaSaati" v-model="mainData.makinaCalismaSaati" mode="decimal" locale="tr-TR" :minFractionDigits="0" :min="0" :disabled="isDisabled"/>
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="servisUcretlimi">Servis Ücretli mi?</label>
								<Dropdown id="servisUcretlimi" v-model="mainData.servisUcretlimi" :options="SM_servisUcretlimi" optionLabel="Value" optionValue="AttributeValue" placeholder="Servis Ücretli mi?" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="sonuc">Sonuç <span style="color:red">*</span></label>
								<Dropdown id="sonuc" v-model="mainData.sonuc" :options="SM_sonuc" optionLabel="Value" optionValue="AttributeValue" placeholder="Sonuç" :showClear="true" :disabled="isDisabled" />
								<span v-if="v$.mainData.sonuc.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.sonuc.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-8">
							<div class="field p-fluid">
								<label for="yapilanlar">Yapılanlar <span style="color:red">*</span></label>
								<Textarea id="yapilanlar" rows="6" :autoResize="false" v-model="mainData.yapilanlar" :disabled="isDisabled" />
								<span v-if="v$.mainData.yapilanlar.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.yapilanlar.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="ilave">İlave</label>
								<Textarea id="ilave" rows="6" :autoResize="false" v-model="mainData.ilave" :disabled="isDisabled" />
							</div>
						</div>
							
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>MOBİL UYGULAMADAN GELENLER</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="imzalayanFirmaPersoneli">İmzalayan Firma Personeli</label>
								<InputText id="imzalayanFirmaPersoneli" type="text" v-model="mainData.imzalayanFirmaPersoneli" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="servisRaporunuHazirlayanTeknikPersonelName">Hazırlayan Teknik Personel</label>
								<InputText id="servisRaporunuHazirlayanTeknikPersonelName" type="text" v-model="mainData.servisRaporunuHazirlayanTeknikPersonelName" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="servisRaporunuimzalayanTeknikPersonelName">İmzalayan Teknik Personel</label>
								<InputText id="servisRaporunuimzalayanTeknikPersonelName" type="text" v-model="mainData.servisRaporunuimzalayanTeknikPersonelName" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="taslak">Taslak</label>
								<Dropdown id="taslak" v-model="mainData.taslak" :options="SM_taslak" optionLabel="Value" optionValue="AttributeValue" placeholder="Taslak" :showClear="true" />
							</div>
						</div>
						<div class="col-6">
							<div class="field p-fluid">
								<label for="gonderilenMailAdresleri">Gönderilen Mail Adresleri</label>
								<InputText id="gonderilenMailAdresleri" type="text" v-model="mainData.gonderilenMailAdresleri" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="tezgahDurumu">Tezgah Durumu <span style="color:red">*</span></label>
								<Dropdown id="tezgahDurumu" v-model="mainData.tezgahDurumu" :options="SM_tezgahDurumu" optionLabel="Value" optionValue="AttributeValue" placeholder="Tezgah Durumu" :showClear="true" disabled />
								<span v-if="v$.mainData.tezgahDurumu.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.tezgahDurumu.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="makinaGuvenlikKilidi">Makina Güvenlik Kilidi <span style="color:red">*</span></label>
								<Dropdown id="makinaGuvenlikKilidi" v-model="mainData.makinaGuvenlikKilidi" :options="SM_makinaGuvenlikKilidi" optionLabel="Value" optionValue="AttributeValue" placeholder="Makina Güvenlik Kilidi" :showClear="true" disabled />
								<span v-if="v$.mainData.makinaGuvenlikKilidi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.makinaGuvenlikKilidi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="torna1">Torna 1</label>
								<InputText id="torna1" type="text" v-model="mainData.torna1" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="torna2">Torna 2</label>
								<InputText id="torna2" type="text" v-model="mainData.torna2" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="torna3">Torna 3</label>
								<InputText id="torna3" type="text" v-model="mainData.torna3" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="torna4">Torna 4</label>
								<InputText id="torna4" type="text" v-model="mainData.torna4" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="islemeMerkezi1">İşleme Merkezi 1</label>
								<InputText id="islemeMerkezi1" type="text" v-model="mainData.islemeMerkezi1" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="islemeMerkezi2">İşleme Merkezi 2</label>
								<InputText id="islemeMerkezi2" type="text" v-model="mainData.islemeMerkezi2" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="islemeMerkezi3">İşleme Merkezi 3</label>
								<InputText id="islemeMerkezi3" type="text" v-model="mainData.islemeMerkezi3" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="islemeMerkezi4">İşleme Merkezi 4</label>
								<InputText id="islemeMerkezi4" type="text" v-model="mainData.islemeMerkezi4" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="hizmetdegerlendirmepuani">Hizmet Değerlendirme Puanı</label>
								<InputText id="hizmetdegerlendirmepuani" type="text" v-model="mainData.hizmetDegerlendirmePuani" disabled />
							</div>
						</div>
						<div class="col-9">
							<div class="field p-fluid">
								<label for="hizmetdegerlendirmeyorum">Hizmet Değerlendirme Yorum</label>
								<InputText id="hizmetdegerlendirmeyorum" type="text" v-model="mainData.hizmetDegerlendirmeYorum" disabled />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<div class="flex align-items-center">
						<div class="mr-3" style="height:39px">
							<h5 style="line-height: 39px;">PARÇALAR</h5>
						</div>
						<div style="height:39px">
							<Button v-if="mainData.sonuc != 930710000" icon="pi pi-plus" class="p-button-rounded" @click="displayDialogParcalar = true" />
						</div>
					</div>
					<DataTable
						:value="parcalarData"
						selectionMode="single"
						:rowHover="true"
						@rowSelect="onRowSelectParcalar($event)"
						responsiveLayout="scroll">
						<template #empty>
							Kayıt Bulunamadı.
						</template>
						<template #loading>
							Veriler yükleniyor, lütfen bekleyiniz...
						</template>
						<Column field="adet" header="Adet" headerStyle="width:100px"></Column>
						<Column field="parcaNo" header="Parça No" headerStyle="width:200px"></Column>
						<Column field="parcaAdi" header="Parça Adı"></Column>
						<Column field="aciklama" header="Açıklama"></Column>
					</DataTable>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<div class="flex align-items-center">
						<div class="mr-3" style="height:39px">
							<h5 style="line-height: 39px;">ÇALIŞMA SAATLERİ</h5>
						</div>
						<div style="height:39px">
							<Button v-if="mainData.sonuc != 930710000" icon="pi pi-plus" class="p-button-rounded" @click="onClickDialogCalismaSaatleri()" />
						</div>
					</div>
					
					<DataTable
						:value="calismaSaatleriData"
						selectionMode="single"
						:rowHover="true"
						@rowSelect="onRowSelectCalismaSaatleri($event)"
						responsiveLayout="scroll">
						<template #empty>
							Kayıt Bulunamadı.
						</template>
						<template #loading>
							Veriler yükleniyor, lütfen bekleyiniz...
						</template>
						<Column field="baslangicFormatted" header="Başlangıç" headerStyle="width:135px"></Column>
						<Column field="bitisFormatted" header="Bitiş" headerStyle="width:135px"></Column>
						<Column field="sure" header="Süre" headerStyle="width:100px"></Column>
						<Column field="fazlaMesai50" header="F. Mesai %50" headerStyle="width:90px"></Column>
						<Column field="fazlaMesai100" header="F. Mesai %100" headerStyle="width:90px"></Column>
						<Column field="ucak" header="Uçak" dataType="boolean" bodyClass="text-left" headerStyle="width:90px">
							<template #body="{data}">
								<i class="pi" :class="{'true-icon pi-check-circle': data.ucak, 'false-icon pi-times-circle': !data.ucak}"></i>
							</template>
						</Column>
						<Column field="aracKiralama" header="A.Kiralama" dataType="boolean" bodyClass="text-left" headerStyle="width:90px">
							<template #body="{data}">
								<i class="pi" :class="{'true-icon pi-check-circle': data.aracKiralama, 'false-icon pi-times-circle': !data.aracKiralama}"></i>
							</template>
						</Column>
						<Column field="konaklama" header="Konaklama" dataType="boolean" bodyClass="text-left" headerStyle="width:90px">
							<template #body="{data}">
								<i class="pi" :class="{'true-icon pi-check-circle': data.konaklama, 'false-icon pi-times-circle': !data.konaklama}"></i>
							</template>
						</Column>
						<Column field="seyahat" header="Seyahat" headerStyle="width:90px"></Column>
						<Column field="kaynaklar" header="Kaynaklar"></Column>
					</DataTable>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<div class="flex align-items-center">
						<div class="mr-3" style="height:39px">
							<h5 style="line-height: 39px;">SERVİS MALİYETİ</h5>
						</div>
						<div style="height:39px">
							<Button v-if="servisMaliyetiData.servismaliyetId != undefined && isSeviyeServis == true" icon="pi pi-pencil" label="Güncelle" class="p-button-rounded" @click="displayServisMaliyetiGuncelle = true" />
							<Button v-if="servisMaliyetiData.servismaliyetId != undefined && isSeviyeServis == true" icon="pi pi-times-circle" label="Sil" class="p-button-rounded p-button-danger" @click="OnServisMaliyetiSil" />
							<Button v-if="isSeviyeServis == true" icon="pi pi-replay" label="Yeniden Oluştur" class="p-button-rounded p-button-success" @click="OnServisMaliyetiOlustur" />
						</div>
					</div>

					<div class="grid" v-if="servisMaliyetiData.servismaliyetId != undefined" >
						<div class="col-6">
							<table class="maliyetTable">
								<thead>
									<tr>
										<th></th>
										<th>Birim Fiyat (€)</th>
										<th>Çalışma Saatleri</th>
										<th>Toplam Fiyat</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td class="baslik">İlk Saat Ücreti</td>
										<td class="maliyetBirimFiyat">{{ servisMaliyetiData.ilkSaatBirimUcreti }} €</td>
										<td>{{ formatNumber(servisMaliyetiData.ilkSaatCalismaSaati) }}</td>
										<td style="text-align:right; font-weight: bold;">{{ formatNumber(servisMaliyetiData.ilkSaatTutari) }} €</td>
									</tr>
									<tr>
										<td class="baslik">Sonraki Saat Ücreti</td>
										<td class="maliyetBirimFiyat">{{ servisMaliyetiData.saatBirimUcreti }} €</td>
										<td>{{ formatNumber(servisMaliyetiData.saatCalismaSaati) }}</td>
										<td style="text-align:right; font-weight: bold;">{{ formatNumber(servisMaliyetiData.saatTutari) }} €</td>
									</tr>
									<tr>
										<td class="baslik">%50 Fazla Mesai</td>
										<td class="maliyetBirimFiyat">{{ servisMaliyetiData.fazlaMesai50BirimUcreti}} €</td>
										<td>{{ formatNumber(servisMaliyetiData.fazlaMesai50calismaSaati) }}</td>
										<td style="text-align:right; font-weight: bold;">{{ formatNumber(servisMaliyetiData.fazlaMesai50Tutari) }} €</td>
									</tr>
									<tr>
										<td class="baslik">%100 Fazla Mesai</td>
										<td class="maliyetBirimFiyat">{{ servisMaliyetiData.fazlaMesai100BirimUcreti}} €</td>
										<td>{{ formatNumber(servisMaliyetiData.fazlaMesai100calismaSaati) }}</td>
										<td style="text-align:right; font-weight: bold;">{{ formatNumber(servisMaliyetiData.fazlaMesai100Tutari) }} €</td>
									</tr>
									<tr>
										<td class="baslik">Yol Ücreti</td>
										<td class="maliyetBirimFiyat">{{ servisMaliyetiData.yolBirimUcreti}} €</td>
										<td>{{ formatNumber(servisMaliyetiData.yolSaati) }}</td>
										<td style="text-align:right; font-weight: bold;">{{ formatNumber(servisMaliyetiData.yolTutari) }} €</td>
									</tr>
									<tr>
										<td class="baslik">Konaklama</td>
										<td class="maliyetBirimFiyat">{{ servisMaliyetiData.konaklamaBirimUcreti}} €</td>
										<td>{{ formatNumber(servisMaliyetiData.konaklamaGunu) }}</td>
										<td style="text-align:right; font-weight: bold;">{{ formatNumber(servisMaliyetiData.konaklamaTutari) }} €</td>
									</tr>
									<tr>
										<td colspan="2" style="border-left-style: hidden; border-bottom-style: hidden"></td>
										<td style="text-align:right; font-weight: bold; font-size: 1.1rem; color:#16a085">Toplam Tutar : </td>
										<td style="text-align:right; font-weight: bold; font-size: 1.1rem; color:#16a085">{{ formatNumber(servisMaliyetiData.toplamTutar) }} €</td>
									</tr>
									<tr>
										<td colspan="2" style="border-left-style: hidden; border-bottom-style: hidden"></td>
										<td style="text-align:right; font-weight: bold; font-size: 1.1rem; color:#16a085">İndirim Oranı % : </td>
										<td style="text-align:right; font-weight: bold; font-size: 1.1rem; color:#16a085">{{ formatNumber(servisMaliyetiData.indirimOrani) }} %</td>
									</tr>
									<tr>
										<td colspan="2" style="border-left-style: hidden; border-bottom-style: hidden"></td>
										<td style="background-color:#ecf0f1; text-align:right; font-weight: bold; font-size: 1.1rem; color:#e74c3c">Genel Toplam : </td>
										<td style="background-color:#ecf0f1; text-align:right; font-weight: bold; font-size: 1.1rem; color:#e74c3c">{{ formatNumber(servisMaliyetiData.genelToplam) }} €</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<Annotation objectTypeCode="4214" logicalName="serviceappointment" :objectId="entityId" ></Annotation>
			</div>

			<Dialog header="Çalışma Saati Ekle" v-model:visible="displayDialogCalismaSaatleri" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true" @show="onShowDialogCalismaSaatleri">
				<div class="grid p-1">
					<div class="col-4">
						<div class="field p-fluid">
							<label for="baslangicTarihi">Başlangıç <span style="color:red">*</span></label>
							<Calendar showButtonBar id="baslangicTarihi" v-model="dialogCalismaSaatleriData.baslangic" :showIcon="true" :stepMinute="30" :stepSecond="60" :showTime="true" :showSeconds="false" @date-select="baslangicTarihiDateSelect" @input="baslangicTarihiInput" />
						</div>
					</div>
					<div class="col-4">
						<div class="field p-fluid">
							<label for="bitisTarihi">Bitiş <span style="color:red">*</span></label>
							<Calendar showButtonBar id="bitisTarihi" v-model="dialogCalismaSaatleriData.bitis" :showIcon="true" :stepMinute="30" :stepSecond="60" :showTime="true" :showSeconds="false" @date-select="bitisTarihiDateSelect" @input="bitisTarihiInput" />
						</div>
					</div>
					<div class="col-4">
						<div class="field p-fluid">
							<label for="sure">Süre</label>
							<InputNumber id="sure" v-model="dialogCalismaSaatleriData.sure" mode="decimal" locale="tr-TR" :minFractionDigits="2" :min="0" disabled />
						</div>
					</div>
					<div class="col-6">
						<div class="field p-fluid">
							<label for="fazlamesai50">Fazla Mesai %50</label>
							<InputNumber id="fazlamesai50" v-model="dialogCalismaSaatleriData.fazlaMesai50" mode="decimal" locale="tr-TR" :minFractionDigits="2" :min="0" disabled/>
						</div>
					</div>
					<div class="col-6">
						<div class="field p-fluid">
							<label for="fazlamesai100">Fazla Mesai %100</label>
							<InputNumber id="fazlamesai100" v-model="dialogCalismaSaatleriData.fazlaMesai100" mode="decimal" locale="tr-TR" :minFractionDigits="2" :min="0" disabled />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="ucak">Uçak</label>
							<Dropdown id="ucak" v-model="dialogCalismaSaatleriData.ucak" :options="SM_ucak" optionLabel="Value" optionValue="AttributeValue" placeholder="Uçak" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="konaklama">Konaklama</label>
							<Dropdown id="konaklama" v-model="dialogCalismaSaatleriData.konaklama" :options="SM_konaklama" optionLabel="Value" optionValue="AttributeValue" placeholder="Konaklama" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="aracKiralama">Araç Kiralama</label>
							<Dropdown id="aracKiralama" v-model="dialogCalismaSaatleriData.aracKiralama" :options="SM_arackiralama" optionLabel="Value" optionValue="AttributeValue" placeholder="Araç Kiralama" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="seyahat">Seyahat</label>
							<InputNumber id="seyahat" v-model="dialogCalismaSaatleriData.seyahat" mode="decimal" locale="tr-TR" :minFractionDigits="2" :min="0" />
						</div>
					</div>
					<div class="col-12">
						<div class="field p-fluid">
							<label for="kaynaklar">Kaynaklar</label>
							<InputText id="kaynaklar" type="text" v-model="dialogCalismaSaatleriData.kaynaklar" />
						</div>
					</div>
				</div>
				<template #footer>
					<Button v-if="dialogCalismaSaatleriData.entityId" label="Sil" icon="pi pi-trash" class="p-button-danger" @click="OnDeleteCalismaSaatleri" />
					<Button label="Kaydet" icon="pi pi-plus" @click="OnSaveCalismaSaatleri" />
				</template>
			</Dialog>

			<Dialog header="Parça Ekle" v-model:visible="displayDialogParcalar" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true" @show="onShowDialogParcalar">
				<div class="grid">
					<div class="col-6">
						<div class="field p-fluid">
							<label for="adet">Adet <span style="color:red">*</span></label>
							<InputText id="adet" type="text" v-model="dialogParcalarData.adet" />
						</div>
					</div>
					<div class="col-6">
						<div class="field p-fluid">
							<label for="parcaNo">Parça No</label>
							<InputText id="parcaNo" type="text" v-model="dialogParcalarData.parcaNo" />
						</div>
					</div>
					<div class="col-12">
						<div class="field p-fluid">
							<label for="parcaAdi">Parça Adı <span style="color:red">*</span></label>
							<InputText id="parcaAdi" type="text" v-model="dialogParcalarData.parcaAdi" />
						</div>
					</div>
					<div class="col-12">
						<div class="field p-fluid">
							<label for="aciklama">Açıklama</label>
							<InputText id="aciklama" type="text" v-model="dialogParcalarData.aciklama" />
						</div>
					</div>
				</div>
				<template #footer>
					<Button label="Kaydet" icon="pi pi-plus" @click="OnSaveParcalar" />
				</template>
			</Dialog>

			<Dialog header="Servis Maliyeti Güncelle" v-model:visible="displayServisMaliyetiGuncelle" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
				<div class="grid">
					<div class="col-12">
						<br><br>
						<table class="maliyetTable">
							<thead>
								<tr>
									<th></th>
									<th>Birim Fiyat (€)</th>
									<th>Çalışma Saatleri</th>
									<th>Toplam Fiyat</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td class="baslik">İlk Saat Ücreti</td>
									<td class="maliyetBirimFiyat">
										<InputNumber v-model="servisMaliyetiData.ilkSaatBirimUcreti" mode="decimal" locale="tr-TR" suffix=" €" :minFractionDigits="2" :min="0" @input="changeMaliyet_ilkSaatBirimUcreti" />
									</td>
									<td>
										<InputNumber v-model="servisMaliyetiData.ilkSaatCalismaSaati" mode="decimal" locale="tr-TR" :minFractionDigits="2" :min="0" @input="changeMaliyet_ilkSaatCalismaSaati" />
									</td>
									<td style="text-align:right; font-weight: bold;">{{ formatNumber(servisMaliyetiData.ilkSaatTutari) }} €</td>
								</tr>
								<tr>
									<td class="baslik">Sonraki Saat Ücreti</td>
									<td class="maliyetBirimFiyat">
										<InputNumber v-model="servisMaliyetiData.saatBirimUcreti" mode="decimal" locale="tr-TR" suffix=" €" :minFractionDigits="2" :min="0" @input="changeMaliyet_saatBirimUcreti" />
									</td>
									<td>
										<InputNumber v-model="servisMaliyetiData.saatCalismaSaati" mode="decimal" locale="tr-TR" :minFractionDigits="2" :min="0" @input="changeMaliyet_saatCalismaSaati" />
									</td>
									<td style="text-align:right; font-weight: bold;">{{ formatNumber(servisMaliyetiData.saatTutari) }} €</td>
								</tr>
								<tr>
									<td class="baslik">%50 Fazla Mesai</td>
									<td class="maliyetBirimFiyat">
										<InputNumber v-model="servisMaliyetiData.fazlaMesai50BirimUcreti" mode="decimal" locale="tr-TR" suffix=" €" :minFractionDigits="2" :min="0" @input="changeMaliyet_fazlaMesai50BirimUcreti" />
									</td>
									<td>
										<InputNumber v-model="servisMaliyetiData.fazlaMesai50calismaSaati" mode="decimal" locale="tr-TR" :minFractionDigits="2" :min="0" @input="changeMaliyet_fazlaMesai50calismaSaati" />
									</td>
									<td style="text-align:right; font-weight: bold;">{{ formatNumber(servisMaliyetiData.fazlaMesai50Tutari) }} €</td>
								</tr>
								<tr>
									<td class="baslik">%100 Fazla Mesai</td>
									<td class="maliyetBirimFiyat">
										<InputNumber v-model="servisMaliyetiData.fazlaMesai100BirimUcreti" mode="decimal" locale="tr-TR" suffix=" €" :minFractionDigits="2" :min="0" @input="changeMaliyet_fazlaMesai100BirimUcreti" />
									</td>
									<td>
										<InputNumber v-model="servisMaliyetiData.fazlaMesai100calismaSaati" mode="decimal" locale="tr-TR" :minFractionDigits="2" :min="0" @input="changeMaliyet_fazlaMesai100calismaSaati" />
									</td>
									<td style="text-align:right; font-weight: bold;">{{ formatNumber(servisMaliyetiData.fazlaMesai100Tutari) }} €</td>
								</tr>
								<tr>
									<td class="baslik">Yol Ücreti</td>
									<td class="maliyetBirimFiyat">
										<InputNumber v-model="servisMaliyetiData.yolBirimUcreti" mode="decimal" locale="tr-TR" suffix=" €" :minFractionDigits="2" :min="0" @input="changeMaliyet_yolBirimUcreti" />
									</td>
									<td>
										<InputNumber v-model="servisMaliyetiData.yolSaati" mode="decimal" locale="tr-TR" :minFractionDigits="2" :min="0" @input="changeMaliyet_yolSaati" />
									</td>
									<td style="text-align:right; font-weight: bold;">{{ formatNumber(servisMaliyetiData.yolTutari) }} €</td>
								</tr>
								<tr>
									<td class="baslik">Konaklama</td>
									<td class="maliyetBirimFiyat">
										<InputNumber v-model="servisMaliyetiData.konaklamaBirimUcreti" mode="decimal" locale="tr-TR" suffix=" €" :minFractionDigits="2" :min="0" @input="changeMaliyet_konaklamaBirimUcreti" />
									</td>
									<td>
										<InputNumber v-model="servisMaliyetiData.konaklamaGunu" mode="decimal" locale="tr-TR" :minFractionDigits="2" :min="0" @input="changeMaliyet_konaklamaGunu" />
									</td>
									<td style="text-align:right; font-weight: bold;">{{ formatNumber(servisMaliyetiData.konaklamaTutari) }} €</td>
								</tr>
								<tr>
									<td colspan="2" style="border-left-style: hidden; border-bottom-style: hidden"></td>
									<td style="text-align:right; font-weight: bold; font-size: 1.1rem; color:#16a085">Toplam Tutar : </td>
									<td style="text-align:right; font-weight: bold; font-size: 1.1rem; color:#16a085">{{ formatNumber(maliyetToplamTutar) }} €</td>
								</tr>
								<tr>
									<td colspan="2" style="border-left-style: hidden; border-bottom-style: hidden"></td>
									<td style="text-align:right; font-weight: bold; font-size: 1.1rem; color:#16a085">İndirim Oranı % : </td>
									<td style="text-align:right; font-weight: bold; font-size: 1.1rem; color:#16a085">
										<InputNumber :key="keyServisMaliyetiGuncelle_indirimOrani" v-model="servisMaliyetiData.indirimOrani" mode="decimal" locale="tr-TR" suffix=" %" :minFractionDigits="2" :min="0" @input="changeMaliyet_indirimOrani" />
									</td>
								</tr>
								<tr>
									<td colspan="2" style="border-left-style: hidden; border-bottom-style: hidden"></td>
									<td style="background-color:#ecf0f1; text-align:right; font-weight: bold; font-size: 1.1rem; color:#e74c3c">Genel Toplam : </td>
									<td style="background-color:#ecf0f1; text-align:right; font-weight: bold; font-size: 1.1rem; color:#e74c3c">{{ formatNumber(maliyetGenelToplam) }} €</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<template #footer>
					<Button label="Güncelle" icon="pi pi-pencil" @click="OnSaveServisMaliyeti" />
				</template>
			</Dialog>
			
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 4214,
			mainData: {},
			servisMaliyetiData: {},
			parcalarData: [],
			calismaSaatleriData: [],

			resourcesData: [],
			selectedResources: null,

			displayDialogCalismaSaatleri: false,
			dialogCalismaSaatleriData: {},
			displayDialogParcalar: false,
			dialogParcalarData: {},
			displayServisMaliyetiGuncelle: false,
			keyServisMaliyetiGuncelle_indirimOrani: 0,

			nestedMenuitems: [
				{
					label: 'Kaydet',
					icon: 'pi pi-fw pi-save',
					visible: () => !this.isDisabled,
					command: () => { this.OnSave(false); },
                },
				{
					label: 'Kaydet & Kapat',
					icon: 'pi pi-fw pi-save',
					visible: () => !this.isDisabled,
					command: () => { this.OnSave(true); },
                },
				{
                    label: "Sil",
                    icon: "pi pi-fw pi-trash",
                    command: () => { this.OnDelete(); },
					visible: () => this.isDeleted
                },
				{
					label: 'Tamamlandı Yap',
					icon: 'pi pi-fw pi-save',
					visible: () => !this.isDisabled,
					command: () => { this.OnSaveTamamlandi(); },
                },
				{
					label: 'Servis Raporu',
					icon:'pi pi-fw pi-print',
					command: () => { this.GotoReport('ServisFormu', 'guid'); },
                },
				{
					label: 'Servis Raporu (Mobil)',
					icon:'pi pi-fw pi-print',
					command: () => { this.GotoReport('ServisFormu_newMobil', 'guid'); },
                },
				{
					label: 'Yeniden ETKİNLEŞTİR',
					icon: 'pi pi-fw pi-history',
					visible: () => this.isDisabled,
					command: () => { this.ServisRaporunuYenidenEtkinlestir(); },
                },
			]
		}
	},
	async created() {
		this.crmService = new CrmService();

		console.log('created');
		window.addEventListener('scroll', this.handleScroll);

		this.OnLoad();

		if (this.$route.params.incidentid) {
			let data = await this.crmService.getServisTalebiById(this.$route.params.incidentid);
			if (data.jsonData.length > 0) {
				let jsonDataIncident = data.jsonData[0];
				this.$refs.entity_servisTalebi.setDefaultData({ "Value": jsonDataIncident.entityId, "Name": jsonDataIncident.baslik });
				this.servisTalebiSelected = { "Value": jsonDataIncident.entityId, "Name": jsonDataIncident.baslik };

				this.$refs.entity_firma.setDefaultData({ "Value": jsonDataIncident.firmaId, "Name": jsonDataIncident.firmaName });
				this.firmaSelected = { "Value": jsonDataIncident.firmaId, "Name": jsonDataIncident.firmaName };
				debugger;
			}
		}
	},
	computed: {
		entityId() {
			return this.$route.params.id;
		},
		profileData() {
			return this.$store.getters.getProfile;
		},
		isDisabled() {
			let _isYetkisiVarmi = false;
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Servis Raporları');
					if (filtered.length > 0) {
						_isYetkisiVarmi = filtered[0].isWrite == true;
					}
				}
			}

			let _durumKapalimi = this.mainData.sonuc == 930710000;

			if (_isYetkisiVarmi == false || _durumKapalimi) {
				return true;
			}
			else {
				return false;
			}
		},
		isDeleted: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Servis Raporları');
					if (filtered.length > 0) {
						return filtered[0].isDelete == true;
					}
				}
			}

			return false;
		},
		isSeviyeServis(){
			let isSeviye = (this.profileData.guvenlikSeviyeleri.indexOf("Servis Yönetici") > -1);
			return isSeviye;
		},
		SM_turu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_turu');
		},
		SM_servisUcretlimi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_servisucretlimi');
		},
		SM_sonuc: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_durum');
		},
		SM_taslak: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_taslak', 'bool');
		},
		SM_tezgahDurumu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_tezgahdurumu');
		},
		SM_makinaGuvenlikKilidi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_makinaguvenlikkilidi');
		},
		SM_ucak: function () {
			return this.$store.getters.getStringMapByEntityId(10024, 'bm_ucak_bool', 'bool');
		},
		SM_konaklama: function () {
			return this.$store.getters.getStringMapByEntityId(10024, 'bm_konaklama_bool', 'bool');
		},
		SM_arackiralama: function () {
			return this.$store.getters.getStringMapByEntityId(10024, 'bm_arackiralama_bool', 'bool');
		},
		firmaSelected: {
			get: function () {
				if (this.mainData["firmaId"]) {
					return { "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["firmaId"] = null;
					this.mainData["firmaName"] = null;
				}
				else {
					this.mainData["firmaId"] = newValue.Value;
					this.mainData["firmaName"] = newValue.Name;
				}
			}
		},
		servisTalebiSelected: {
			get: function () {
				if (this.mainData["servisTalebiId"]) {
					return { "Value": this.mainData["servisTalebiId"], "Name": this.mainData["servisTalebiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["servisTalebiId"] = null;
					this.mainData["servisTalebiName"] = null;
				}
				else {
					this.mainData["servisTalebiId"] = newValue.Value;
					this.mainData["servisTalebiName"] = newValue.Name;

					this.crmService.GetEntityById({
						"entityName": "incident",
						"entityId": newValue.Value,
						"columnNames": ["bm_uniteid", "productid"]})
					.then(data => {
						if (data.jsonData != null) {
							debugger;

							this.$refs.entity_tezgah.setDefaultData({ "Value": data.jsonData["bm_uniteid"], "Name": data.jsonData["bm_uniteidName"] });
							this.$refs.entity_urun.setDefaultData({ "Value": data.jsonData["productid"], "Name": data.jsonData["productidName"] });

							this.tezgahSelected = { "Value": data.jsonData["bm_uniteid"], "Name": data.jsonData["bm_uniteidName"] };
							this.urunSelected = { "Value": data.jsonData["productid"], "Name": data.jsonData["productidName"] };
						}
					})
					.catch(error => console.log(error));
				}
			}
		},
		tezgahSelected: {
			get: function () {
				if (this.mainData["tezgahId"]) {
					return { "Value": this.mainData["tezgahId"], "Name": this.mainData["tezgahName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["tezgahId"] = null;
					this.mainData["tezgahName"] = null;
				}
				else {
					this.mainData["tezgahId"] = newValue.Value;
					this.mainData["tezgahName"] = newValue.Name;
				}
			}
		},
		urunSelected: {
			get: function () {
				if (this.mainData["urunId"]) {
					return { "Value": this.mainData["urunId"], "Name": this.mainData["urunName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["urunId"] = null;
					this.mainData["urunName"] = null;
				}
				else {
					this.mainData["urunId"] = newValue.Value;
					this.mainData["urunName"] = newValue.Name;
				}
			}
		},
		maliyetToplamTutar: function () {
			return this.servisMaliyetiData.ilkSaatTutari  + this.servisMaliyetiData.saatTutari + this.servisMaliyetiData.fazlaMesai50Tutari + this.servisMaliyetiData.fazlaMesai100Tutari + this.servisMaliyetiData.yolTutari + this.servisMaliyetiData.konaklamaTutari; 
		},
		maliyetGenelToplam: function () {
			if (this.servisMaliyetiData.indirimOrani > 0) {
				debugger;
				const indirimTutari = (this.servisMaliyetiData.indirimOrani / 100) * this.maliyetToplamTutar;
				return this.maliyetToplamTutar  - indirimTutari; 
			}
			else {
				return this.maliyetToplamTutar;
			}
		},
	},
	methods: {
		async OnLoad(){
			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});

			debugger;
			if (this.entityId != null) {
				try {
					debugger;

					let data = await this.crmService.getServisRaporuById(this.entityId);
					if (data.yetkiVarmi == false) {
						this.$router.replace({ name: 'accessdenied' });
					}
					
					if (data.jsonData.length > 0) {
						this.mainData = data.jsonData[0];
						this.parcalarData = data.parcalarData;
						this.calismaSaatleriData = data.calismaSaatleriData;
						this.selectedResources = data.selectedResources;

						const pageHistoryItem = { entityLetter: 'SRa', entityName: 'serviceappointment', ID: this.mainData.entityId, title: this.mainData.kod + '\r\n\r\n' + this.mainData.firmaName };
						this.$store.commit('SET_PAGEHISTORY', pageHistoryItem);

						if (this.mainData["firmaId"]) {
							this.$refs.entity_firma.setDefaultData({ "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] });
						}

						if (this.mainData["servisTalebiId"]) {
							this.$refs.entity_servisTalebi.setDefaultData({ "Value": this.mainData["servisTalebiId"], "Name": this.mainData["servisTalebiName"] });
						}

						if (this.mainData["tezgahId"]) {
							this.$refs.entity_tezgah.setDefaultData({ "Value": this.mainData["tezgahId"], "Name": this.mainData["tezgahName"] });
						}

						if (this.mainData["urunId"]) {
							this.$refs.entity_urun.setDefaultData({ "Value": this.mainData["urunId"], "Name": this.mainData["urunName"] });
						}

						if (this.mainData["servisTalepTarihi"]) {
							this.mainData["servisTalepTarihi"] = new Date(this.mainData.servisTalepTarihi);
						}
					}

					let servisMaliyetiData_temp = await this.crmService.getServisMaliyetiById(this.entityId);
					if (servisMaliyetiData_temp.jsonData.length > 0) {
						this.servisMaliyetiData = servisMaliyetiData_temp.jsonData[0];
					}

					this.resourcesData = await this.crmService.GetResources();
					this.resourcesData = this.resourcesData.jsonData;
				} catch (error) {
					console.log(error);
					//this.$router.push('/');
				}
				finally {
					loader.hide();
				}
			}
			else {
				//this.$router.push('/');
				loader.hide();
			}
		},
		async OnSaveTamamlandi(){
			debugger;
			try {
				const response = await this.crmService.getServisRaporuTamamla(this.entityId);
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Servis Raporu Tamamlandı', life: 3000});
						this.OnLoad();
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
			}
		},
		async OnSave(kapat) {
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					if (this.selectedResources.length > 0) {
						this.mainData["kaynaklar"] = this.selectedResources;
					}
					
					const response = await this.crmService.getServisRaporuUpdate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 1000);
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		async OnDelete(){
            this.deleteItem('serviceappointment', this.entityId);
        },
		async OnSaveCalismaSaatleri() {
			debugger;
			try {
				var hatalar =[]; 
				if (this.dialogCalismaSaatleriData.baslangic == null) {
					hatalar.push('Başlangıç Tarihi giriniz!');
				}

				if (this.dialogCalismaSaatleriData.bitis == null) {
					hatalar.push('Bitiş Tarihi giriniz!');
				}

				if (hatalar.length > 0) {
					hatalar.forEach(hata => {
						this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: hata, life: 3500 });
					});
				}
				else {
					this.dialogCalismaSaatleriData["servisRaporuId"] = this.entityId;
					const response = await this.crmService.getCreateUpdateCalismaSaati(this.dialogCalismaSaatleriData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Eklendi', life: 3000});
							this.displayDialogCalismaSaatleri = false;
							this.OnLoad();
						}
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
			}
		},
		async OnDeleteCalismaSaatleri() {
			this.$confirm.require({
				message: 'Bu kaydı silmek istediğinize emin misiniz?',
				header: 'Silme Onayı',
				icon: 'pi pi-trash',
				acceptClass: 'p-button-danger',
				acceptLabel: 'Evet',
				rejectLabel: 'Hayır',
				accept: async () => {
					
					let loader = this.$loading.show({
						container: this.$refs.mainContainer
					});
					
					try {
						const response = await this.crmService.DeleteRecordById('bm_servisraporucalisma', this.dialogCalismaSaatleriData.entityId, 'serviceappointment') ;
						if (response) {
							if (response.hata == true) {
								this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
							}else {
								this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Silindi', life: 3000});
								this.displayDialogCalismaSaatleri = false;
								this.OnLoad();
							}
						}
					} catch (error) {
						this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
					} finally {
						loader.hide();
					}
				}
			});
			
		},
		async OnSaveParcalar() {
			debugger;
			try {
				var hatalar =[]; 
				if (this.dialogParcalarData.parcaAdi == null || this.dialogParcalarData.parcaAdi == "") {
					hatalar.push('Parça Adı giriniz!');
				}

				if (this.dialogParcalarData.adet == null || this.dialogParcalarData.adet == "") {
					hatalar.push('Adet giriniz!');
				}

				if (hatalar.length > 0) {
					hatalar.forEach(hata => {
						this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: hata, life: 3500 });
					});
				}
				else {
					this.dialogParcalarData["servisRaporuId"] = this.entityId;
					const response = await this.crmService.getCreateUpdateParca(this.dialogParcalarData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Eklendi', life: 3000});
							this.dialogParcalarData = {};
							this.displayDialogParcalar = false;
							this.OnLoad();
						}
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
			}
		},
		async OnSaveServisMaliyeti() {
			debugger;
			try {
				this.servisMaliyetiData.toplamTutar = this.maliyetToplamTutar;
				this.servisMaliyetiData.genelToplam = this.maliyetGenelToplam;

				const response = await this.crmService.getServisMaliyetiUpdate(this.servisMaliyetiData);
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Servis Maliyeti GÜNCELLENDİ', life: 3000});
						
						this.displayServisMaliyetiGuncelle = false;
						let servisMaliyetiData_temp = await this.crmService.getServisMaliyetiById(this.entityId);
						if (servisMaliyetiData_temp.jsonData.length > 0) {
							this.servisMaliyetiData = servisMaliyetiData_temp.jsonData[0];
						}
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'Servis Maliyeti Güncelleme Hatası', detail: error.message, life: 3500});
			}
		},
		async OnServisMaliyetiOlustur() {
			debugger;
			this.$confirm.require({
                message: 'Raporun MALİYETİNİ YENİDEN oluşturmak istediğinize emin misiniz? Varsa eski maliyet kaydı silinecektir.',
                header: 'ONAY',
                icon: 'pi pi-times-circle',
                accept: () => {
                    try {
						this.crmService.getServisMaliyetiOlustur(this.entityId)
						.then(response => {
							if (response.hata == true) {
								this.$toast.add({severity:'error', summary: 'HATA', detail: response.hataAciklamasi, life: 5000});
							}else {
								this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Servis Maliyeti YENİDEN Oluşturuldu', life: 3000});
								
								setTimeout(() => {
									this.OnLoad();
								}, 1000);
							}
						})
						.catch(error => {
							debugger;
							console.log(error);
							this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
						});
					} catch (error) {
						debugger;
						console.log(error);
						this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
					}
                },
            });
		},
		async OnServisMaliyetiSil() {
			debugger;
			this.$confirm.require({
                message: 'Raporun MALİYETİNİ SİLMEK istediğinize emin misiniz?',
                header: 'ONAY',
                icon: 'pi pi-times-circle',
                accept: () => {
                    try {
						this.crmService.getServisMaliyetiSil(this.entityId)
						.then(response => {
							if (response.hata == true) {
								this.$toast.add({severity:'error', summary: 'HATA', detail: response.hataAciklamasi, life: 5000});
							}else {
								this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Servis Maliyeti SİLİNMİŞTİR', life: 3000});
								this.servisMaliyetiData = {};
							}
						})
						.catch(error => {
							debugger;
							console.log(error);
							this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
						});
					} catch (error) {
						debugger;
						console.log(error);
						this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
					}
                },
            });
		},
		onRowSelectParcalar(event){
			console.log(event);
			this.dialogParcalarData = event.data;
			this.dialogParcalarData["crudType"] = "update";
			this.displayDialogParcalar = true;
		},
		onShowDialogParcalar(){
			debugger;
			if (this.dialogParcalarData["crudType"] == undefined) {
				this.dialogParcalarData["crudType"] = "create";
			}
		},
		onRowSelectCalismaSaatleri(event){
			debugger;

			this.dialogCalismaSaatleriData = event.data;
			this.dialogCalismaSaatleriData["baslangic"] = new Date(event.data.baslangic);
			this.dialogCalismaSaatleriData["bitis"] = new Date(event.data.bitis);
			this.dialogCalismaSaatleriData["crudType"] = "update";
			this.displayDialogCalismaSaatleri = true;
		},
		ServisRaporunuYenidenEtkinlestir() {
			debugger;
			this.$confirm.require({
                message: 'Raporu ETKİNLEŞTİRMEK istediğinize emin misiniz?',
                header: 'ONAY',
                icon: 'pi pi-times-circle',
                accept: () => {
                    try {
						this.crmService.getServisRaporunuYenidenEtkinlestir(this.entityId)
						.then(response => {
							if (response.hata == true) {
								this.$toast.add({severity:'error', summary: 'HATA', detail: response.hataAciklamasi, life: 5000});
							}else {
								this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Servis Raporu Yeniden Aktifleştirildi', life: 3000});
								this.OnLoad();
							}
						})
						.catch(error => {
							debugger;
							console.log(error);
							this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
						});
					} catch (error) {
						debugger;
						console.log(error);
						this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
					}
                },
            });
		},
		onClickDialogCalismaSaatleri(){
			debugger;
			this.dialogCalismaSaatleriData = {};

			this.displayDialogCalismaSaatleri = true;
		},
		onShowDialogCalismaSaatleri(){
			debugger;

			if (this.dialogCalismaSaatleriData["crudType"] == undefined) {
				var tarih = new Date();
				tarih.setHours(8);
				tarih.setMinutes(0);

				this.dialogCalismaSaatleriData["baslangic"] = tarih;
				this.dialogCalismaSaatleriData["crudType"] = "create";
				this.dialogCalismaSaatleriData["ucak"] = false;
				this.dialogCalismaSaatleriData["konaklama"] = false;
				this.dialogCalismaSaatleriData["aracKiralama"] = false;
			}
		},
		baslangicTarihiDateSelect(){
			this.baslangicTarihiInput();
		},
		baslangicTarihiInput(){
			debugger;
			if (this.isValidDate(this.dialogCalismaSaatleriData.baslangic)) {
				this.bitisTarihiInput();
			}
		},
		bitisTarihiInput(){
			debugger;
			if (this.isValidDate(this.dialogCalismaSaatleriData.bitis)) {
				this.bitisTarihiDateSelect(this.dialogCalismaSaatleriData.bitis);
			}
		},
		bitisTarihiDateSelect(value){
			debugger;
			let baslangic = this.dialogCalismaSaatleriData.baslangic;
			let bitis = value;

			var hours_ = parseFloat(Math.abs(bitis - baslangic) / 36e5) + parseFloat(0.01);
			var hours = this.round(hours_, 1);

			if (hours) {
				var day = bitis.getDay();
				if (day == 0) { //PAZAR
					this.dialogCalismaSaatleriData["fazlaMesai50"] = 0;
					this.dialogCalismaSaatleriData["fazlaMesai100"] = hours;
					this.dialogCalismaSaatleriData["sure"] = 0;
				}
				else if (day == 6) { //CUMARTESİ
					this.dialogCalismaSaatleriData["fazlaMesai50"] = hours;
					this.dialogCalismaSaatleriData["fazlaMesai100"] = 0;
					this.dialogCalismaSaatleriData["sure"] = 0;
				}
				else { //HAFTA İÇİ
					this.dialogCalismaSaatleriData["fazlaMesai100"] = 0;
					this.dialogCalismaSaatleriData["sure"] = hours;

					var baslangicSaati = parseFloat(baslangic.getHours());
					var baslangicDakika = parseFloat(baslangic.getMinutes());
					
					var bitisSaati = parseFloat(bitis.getHours());
					var bitisDakika = parseFloat(bitis.getMinutes());
					
					if((bitisSaati == 23 && bitisDakika == 59) || (bitisSaati == 0 && bitisDakika == 0)){
						bitisSaati = 24;
						bitisDakika = 0;
					}

					var FazlaMesai_50 = 0;
					debugger;
					if(baslangicSaati >= 0 && baslangicSaati < 8){
						if (baslangicDakika == 30) {
							baslangicSaati = baslangicSaati + 0.5;
						}

						if (bitisDakika == 30) {
							bitisSaati = bitisSaati + 0.5;
						}

						if (bitisSaati > 8) {
							bitisSaati = parseFloat(8);
						}

						FazlaMesai_50 = parseFloat(bitisSaati - baslangicSaati);
						
						this.dialogCalismaSaatleriData["fazlaMesai50"] = FazlaMesai_50;
						this.dialogCalismaSaatleriData["sure"] = hours - FazlaMesai_50;
					}
					else if(baslangicSaati >= 18){
						if (baslangicDakika == 30) {
							baslangicSaati = baslangicSaati + 0.5;
						}
						
						if (bitisDakika == 30) {
							bitisSaati = bitisSaati + 0.5;
						}
						
						FazlaMesai_50 = parseFloat(bitisSaati - baslangicSaati);
						
						this.dialogCalismaSaatleriData["fazlaMesai50"] = FazlaMesai_50;
						this.dialogCalismaSaatleriData["sure"] = hours - FazlaMesai_50;
					}
					else{
						if (bitisSaati >= 18) {
							FazlaMesai_50 = parseFloat(bitisSaati - 18);
							if (bitisDakika == 30) {
								FazlaMesai_50 = FazlaMesai_50 + 0.5;
							}

							this.dialogCalismaSaatleriData["fazlaMesai50"] = FazlaMesai_50;
							this.dialogCalismaSaatleriData["sure"] = hours - FazlaMesai_50;
						}
						else {
							this.dialogCalismaSaatleriData["fazlaMesai50"] = 0;
						}
					}
				}
			}
			else {
				this.dialogCalismaSaatleriData["sure"] = 0;
			}
		},
		changeMaliyet_ilkSaatBirimUcreti(event){
			this.servisMaliyetiData.ilkSaatTutari = event.value * this.servisMaliyetiData.ilkSaatCalismaSaati;
			this.keyServisMaliyetiGuncelle_indirimOrani++;
		},
		changeMaliyet_ilkSaatCalismaSaati(event){
			this.servisMaliyetiData.ilkSaatTutari = event.value * this.servisMaliyetiData.ilkSaatBirimUcreti;
		},
		changeMaliyet_saatBirimUcreti(event){
			this.servisMaliyetiData.saatTutari = event.value * this.servisMaliyetiData.saatCalismaSaati;
		},
		changeMaliyet_saatCalismaSaati(event){
			this.servisMaliyetiData.saatTutari = event.value * this.servisMaliyetiData.saatBirimUcreti;
		},
		changeMaliyet_fazlaMesai50BirimUcreti(event){
			this.servisMaliyetiData.fazlaMesai50Tutari = event.value * this.servisMaliyetiData.fazlaMesai50calismaSaati;
		},
		changeMaliyet_fazlaMesai50calismaSaati(event){
			this.servisMaliyetiData.fazlaMesai50Tutari = event.value * this.servisMaliyetiData.fazlaMesai50BirimUcreti;
		},
		changeMaliyet_fazlaMesai100BirimUcreti(event){
			this.servisMaliyetiData.fazlaMesai100Tutari = event.value * this.servisMaliyetiData.fazlaMesai100calismaSaati;
		},
		changeMaliyet_fazlaMesai100calismaSaati(event){
			this.servisMaliyetiData.fazlaMesai100Tutari = event.value * this.servisMaliyetiData.fazlaMesai100BirimUcreti;
		},
		changeMaliyet_yolBirimUcreti(event){
			this.servisMaliyetiData.yolTutari = event.value * this.servisMaliyetiData.yolSaati;
		},
		changeMaliyet_yolSaati(event){
			this.servisMaliyetiData.yolTutari = event.value * this.servisMaliyetiData.yolBirimUcreti;
		},
		changeMaliyet_konaklamaBirimUcreti(event){
			this.servisMaliyetiData.konaklamaTutari = event.value * this.servisMaliyetiData.konaklamaGunu;
		},
		changeMaliyet_konaklamaGunu(event){
			this.servisMaliyetiData.konaklamaTutari = event.value * this.servisMaliyetiData.konaklamaBirimUcreti;
		},
		changeMaliyet_indirimOrani(event){
			if (event.value) {
				const indirimTutari = (event.value / 100) * this.maliyetToplamTutar;
				this.servisMaliyetiData.genelToplam = this.maliyetToplamTutar - indirimTutari;
			}
			else {
				this.servisMaliyetiData.genelToplam = this.maliyetToplamTutar;
			}
		},
		GotoReport(Name, ParamName){
			const reportUrl = process.env.VUE_APP_WEBAPP_URL + "/SSRS_ReportViewer.aspx?name=" + Name + "&" + ParamName + "=" + this.entityId;
			window.open(reportUrl, 'asd', '_blank');
		},
		formatNumber(value) {
			if (value != undefined) {
				return value.toLocaleString('tr-TR');
			}
		},
		getCurrency() {
			let paraBirimi = '';
			switch (this.mainData.paraBirimiName) {
				case "Dolar":
					paraBirimi = " $"
					break;
				case "Euro":
					paraBirimi = " €"
					break;
				case "İsviçre Frankı":
					paraBirimi = " SFr."
					break;
				case "Türk Lirası":
					paraBirimi = " ₺"
					break;
				case "Yen":
					paraBirimi = " ¥"
					break;

				default:
					break;
			}
			return paraBirimi;
		},
		round(value, precision) {
			var multiplier = Math.pow(10, precision || 0);
			return Math.round(value * multiplier) / multiplier;
		},
		isValidDate(d) {
			return d instanceof Date && !isNaN(d);
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Servis Raporları');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				firmaName: {
					required: helpers.withMessage('Firma alanı dolu olmalıdır!', required),
				},
				servisTalebiName: {
					required: helpers.withMessage('Servis Talebi alanı dolu olmalıdır!', required),
				},
				tezgahName: {
					required: helpers.withMessage('Tezgah alanı dolu olmalıdır!', required),
				},
				urunName: {
					required: helpers.withMessage('Ürün alanı dolu olmalıdır!', required),
				},
				turu: {
					required: helpers.withMessage('Türü alanı dolu olmalıdır!', required),
				},
				sonuc: {
					required: helpers.withMessage('Sonuç alanı dolu olmalıdır!', required),
				},
				yapilanlar: {
					required: helpers.withMessage('Yapılanlar alanı dolu olmalıdır!', required),
				},
				tezgahDurumu: {
					required: helpers.withMessage('Tezgah Durumu alanı dolu olmalıdır!', required),
				},
				makinaGuvenlikKilidi: {
					required: helpers.withMessage('Makina Güvenlik Kilidi alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
	.pi {
		font-family: primeicons;
		speak: none;
		font-style: normal;
		font-weight: 400;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		display: inline-block;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.true-icon {
		color: #256029;
	}

	.false-icon {
		color: #c63737;
	}

	.country-item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	::v-deep(.multiselect-custom) {
		.p-multiselect-label:not(.p-placeholder) {
			padding-top: .50rem;
			padding-bottom: .50rem;
		}

		.country-item-value {
			padding: .25rem .5rem;
			border-radius: 3px;
			display: inline-flex;
			margin-right: .5rem;
			background-color: var(--primary-color);
			color: var(--primary-color-text);

			img.flag {
				width: 17px;
			}
		}
	}

	@media screen and (max-width: 640px) {
		.p-multiselect {
			width: 100%;
		}
	}

	table.maliyetTable {
		border: 1px solid #8E44AD;
		border-collapse: collapse;
		background-color: white;
		width: 100%;
		text-align: center;
	}
	table.maliyetTable td, table.maliyetTable th {
		border: 1px solid #948473;
		padding: 4px 4px;
	}
	table.maliyetTable tbody td {
		font-size: 1rem;
		padding: 7px;
	}
	table.maliyetTable thead {
		background: #ECF0F1;
		background: -moz-linear-gradient(top, #f1f4f4 0%, #eef1f2 66%, #ECF0F1 100%);
		background: -webkit-linear-gradient(top, #f1f4f4 0%, #eef1f2 66%, #ECF0F1 100%);
		background: linear-gradient(to bottom, #f1f4f4 0%, #eef1f2 66%, #ECF0F1 100%);
	}
	table.maliyetTable thead th {
		font-size: 14px;
		font-weight: bold;
		color: #8E44AD;
		text-align: center;
		padding: 7px;
	}

	.baslik {
		background: #ECF0F1;
		background: -moz-linear-gradient(top, #f1f4f4 0%, #eef1f2 66%, #ECF0F1 100%);
		background: -webkit-linear-gradient(top, #f1f4f4 0%, #eef1f2 66%, #ECF0F1 100%);
		background: linear-gradient(to bottom, #f1f4f4 0%, #eef1f2 66%, #ECF0F1 100%);
		font-weight: bold;
		text-align: left;
		color: #8E44AD;
	}

	.maliyetBirimFiyat {
		font-weight: bold;
	}
</style>
